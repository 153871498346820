// Reference: https://paypal.atlassian.net/wiki/spaces/CP20/pages/1106384374/Consumer+Web+-+RUM+Without+Limits+POC
// Based on: https://github.paypal.com/Customers-R/mepnodeweb/pull/2888

import { datadogRum } from '@datadog/browser-rum';

function getDDTraceLink(correlationId, env) {
  switch (env) {
    case 'production':
      return `https://go/ddprod/trace?${correlationId}`;
    case 'development':
    case 'staging':
    default:
      return `https://go/ddqa/trace?${correlationId}`;
  }
}

function getCalLogLink(correlationId, env) {
  switch (env) {
    case 'production':
      return `https://go/ddprod/tocal?${correlationId}`;
    case 'development':
    case 'staging':
    default:
      return `https://go/ddqa/tocal?${correlationId}`;
  }
}

function addCorrelationIdToContext(event, correlationId, env) {
  if (correlationId && typeof correlationId === 'string') {
    event.context.correlationId = correlationId;

    const ddTraceLink = getDDTraceLink(correlationId, env);
    const calLogLink = getCalLogLink(correlationId, env);
    event.context['dd-trace-url'] = ddTraceLink;
    event.context['external-cal-url'] = calLogLink;
  }
}

if (typeof window !== 'undefined' && window.__DD_RUM_CONFIG__) {
  const { applicationId, clientToken, env } = window.__DD_RUM_CONFIG__;

  // init Datadog RUM
  datadogRum.init({
    // properties for identifying and authenticating the application with RUM
    applicationId,
    clientToken,
    site: 'us5.datadoghq.com',
    service: 'brcappnodeweb',
    env: process.env.NODE_ENV,
    // sampling must be set at 100 for session and 0 for recording. retention will be controlled on the backend in Datadog
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    // auto-instrumentations
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    // privacy defaults to prevent leaking PII
    defaultPrivacyLevel: 'mask',
    enablePrivacyForActionName: true,
    // exclude log, tealeaf, and fpti endpoints which are not tied to user interactions from activity tracking
    excludedActivityUrls: [/log/i, /fpti/i, /tealeaf/i, /tracking\/ts/i],
    // exclude log, tealeaf, and fpti endpoints which are not tied to user interactions from universal tracing
    allowedTracingUrls: [
      (url) =>
        !(
          url.includes('log') ||
          url.includes('fpti') ||
          url.includes('tealeaftarget') ||
          url.includes('tracking/ts')
        ),
    ],
    beforeSend(event, context) {
      // modify RUM events to include the correlationId and links to logs and traces
      // for the request because we don't yet have universal tracing
      if (event.type === 'resource') {
        // handle fetch
        if (
          'response' in context &&
          context?.response &&
          context?.response?.headers
        ) {
          const correlationId = context.response.headers.get('Paypal-Debug-Id');
          addCorrelationIdToContext(event, correlationId, env);
        }
        // handle XHR
        if (
          'xhr' in context &&
          context.xhr &&
          'getResponseHeader' in context.xhr &&
          typeof context.xhr.getResponseHeader === 'function'
        ) {
          const correlationId =
            context.xhr.getResponseHeader('Paypal-Debug-Id');
          addCorrelationIdToContext(event, correlationId);
        }
      }
      return true;
    },
  });
}
